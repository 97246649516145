/* eslint-disable no-shadow */
import { LoanPurposeValues } from '@frontend/consumer-loan/enums';
import { IOffer, IWithdrawnOffer } from 'APP/interfaces/private/offers.interfaces';

import { CompanyType } from './analytics.interfaces';
import { ICampaign } from './helpers';

export const LoanPurposeLabels = {
    house_and_home: 'Hus och hem',
    wellness_and_leisure: 'Hälsa/Fritid',
    buy_vehicle: 'Köp av fordon',
    other_consumption: 'Övrig konsumtion',
    consolidate_loans: 'Samla lån',
};
export const MortgageTypeLabels = {
    new: 'Nytt bolån',
    refinance: 'Flytta bolån',
    expand: 'Utöka bolån',
    loan_promise: 'Lånelöfte',
};

export enum MortgageApplicationType {
    new = 'new',
    refinance = 'refinance',
    expand = 'expand',
    loan_promise = 'loan_promise',
}

interface BaseApplication {
    id: string;
    total_loan_amount: number;
}

export interface ConsumerLoanApplication extends BaseApplication {
    applicant_type: 'main' | 'co';
    has_co_applicant: boolean;
    loan_purpose: LoanPurposeValues;
}

export interface BusinessLoanApplication extends BaseApplication {
    company_name: string;
}

export interface MortgageApplication extends BaseApplication {
    applicant_type: 'main' | 'co';
    has_co_applicant: boolean;
    type: MortgageApplicationType;
    address: string;
    unread_messages: number;
}

export interface ActiveApplications {
    consumerLoanApplications: ConsumerLoanApplication[];
    businessLoanApplications: BusinessLoanApplication[];
    mortgageApplications: MortgageApplication[];
}

export interface IApplicationProduct {
    id: string;
    category: string;
    total_loan_amount: number;
    amortize_length: number;
    amount_to_collect: number;
    loan_purpose: string;
}

export interface IApplicationDenial {
    id: string;
    application_id: string;
    revision: number;
    product_id: string;
    product: {
        id: string;
        partner: string;
        partner_information: {
            id: string;
            name: string;
            internal_name: string;
        };
    };
}
export interface IApplicationApplicant {
    id: string;
    ssn: string;
    credit_id: string;
    type: string;
    employment: {
        type: string;
        company: {
            name: string;
            phone: string;
            contact_person: string | null;
        };
        since: {
            year: number;
            month: number;
        };
        to: {
            year: number | null;
            month: number | null;
        };
    };
    application_id: string;
    application_revision: number;
    created_at: string;
    deleted_at: string | null;
    status: string;
    identity_id: string;
    user_id: string;
    credit_expires_at: string;
    pep_id: string;
    claims_pep: string | null;
    has_been_identified: string | null;
    email: string;
    phone: string;
    civil_state: string;
    monthly_income: number;
    number_of_children: number;
    accommodation: {
        type: string;
        monthly_cost: number;
    };
}

export interface IApplication {
    id: string;
    revision: number;
    type: string;
    status: string;
    acceptance: IAcceptance;
    products: IApplicationProduct[];
    applicants: IApplicationApplicant[];
    potential_business_customer: boolean;
    company: CompanyType;
    offers: IOffer[];
    smart_sorted_offers: IOffer[];
    withdrawn_offers: IWithdrawnOffer[];
    denials: IApplicationDenial[];
    created_at: string;
    responses_expired_at: string;
    campaigns: ICampaign[];
    customer_revision_allowed: boolean;
    meta_property_questions_allowed: boolean;
    meta_created_by_gui: boolean;
    private_business?: boolean;
    mortgage_purpose?: string;
}

export interface IAcceptanceResponse {
    parameters: [
        {
            type: string;
            value: string;
        },
    ];
    response_id: string;
    response: {
        id: string;
        parameters: null;
        type: string;
        response_id: string;
        files: string[];
    };
}
export interface IAcceptance {
    application_id: string;
    id: string;
    parameters: { loan_purpose: string; clearing_number?: string; account_number?: string; claims_pep: boolean };
    revision: number;
    responses: IAcceptanceResponse[];
}
export interface IAcceptanceProduct {
    id: string;
}

interface BaseInsuranceProps {
    applicant_id: string;
    created_at: string;
    id: string;
    expires_at: string;
    monthly_cost: number;
    product_id?: string;
    show_from: string;
    status: string;
    type: string;
    product: {};
    insured_amount: number;
}

export interface LoanInsurance extends BaseInsuranceProps {
    product_id: string;
    partner_information: {
        internal_name: string;
    };
    product: {
        name: string;
        partner_information: {
            internal_name: string;
        };
    };
}

export interface SafetyInsurance extends BaseInsuranceProps {
    porcentage: number;

    /** Identifier of insurance. Used to communicate with third party providers so they know what product they are dealing with. */
    customer_insurance_id: string;
}

export interface AccidentInsurance extends BaseInsuranceProps {
    percentage: number;

    /** Identifier of insurance. Used to communicate with third party providers so they know what product they are dealing with. */
    customer_insurance_id: string;
}
