// Used to prevent page content to change width and jump when the scrollbar is toggled from 'body-scroll-lock' npm package.

import { LoanPurposeValues } from '@frontend/consumer-loan/enums';
import { Analytics } from 'APP/Analytics';
import {
    BusinessLoanApplication,
    ConsumerLoanApplication,
    MortgageApplication,
} from 'APP/interfaces/applications.interfaces';

// Taken from https://github.com/willmcpo/body-scroll-lock/blob/79c4cf2c956eb7d5cf8d54a03d12751bc6ac8aa3/src/bodyScrollLock.js#L76
export const getScrollBarWidth = (): number => {
    const scrollBarGap = window.innerWidth - document.documentElement.clientWidth;

    const computedBodyPaddingRight = parseInt(
        window.getComputedStyle(document.body).getPropertyValue('padding-right'),
        10
    );

    return computedBodyPaddingRight + scrollBarGap;
};

export const consumerLoanLinkClick = (application: ConsumerLoanApplication): void => {
    Analytics.pushViewApplicationClick({
        applicationInfo: {
            id: application.id,
            product: 'private',
            purpose: application.loan_purpose,
            amount: application.total_loan_amount,
            hasCoApplicant: application.has_co_applicant,
            collectingLoans: application.loan_purpose === LoanPurposeValues.CONSOLIDATE_LOANS,
            applicantType: application.applicant_type,
        },
        eventInfo: {
            context: 'navbar',
        },
    });
};
export const mortgageLinkClick = (application: MortgageApplication): void => {
    Analytics.pushViewApplicationClick({
        applicationInfo: {
            id: application.id,
            product: 'mortgage',
            subProduct: application.type,
            amount: application.total_loan_amount,
            hasCoApplicant: application.has_co_applicant,
            applicantType: application.applicant_type,
        },
        eventInfo: {
            context: 'navbar',
            activeNotification: application.unread_messages > 0,
        },
    });
};
export const businessLoanLinkClick = (application: BusinessLoanApplication): void => {
    Analytics.pushViewApplicationClick({
        applicationInfo: {
            id: application.id,
            product: 'business',
            amount: application.total_loan_amount,
        },
        eventInfo: {
            context: 'navbar',
        },
    });
};
