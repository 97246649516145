/* eslint-disable no-shadow */
export enum EmploymentType {
    FULL_TIME = 'full_time',
    PENSION = 'pension',
    OWN_COMPANY = 'own_company',
    EARLY_PENSION = 'early_pension',
    HOURLY_EMPLOYMENT = 'hourly_employment',
    FINITIE_TIME = 'finite_time',
    PART_TIME = 'part_time',
    STUDENT = 'student',
    UNEMPLOYED = 'unemployed',
}

export enum LoanPurposeValues {
    HOUSE_AND_HOME = 'house_and_home',
    WELLNESS_AND_LEISURE = 'wellness_and_leisure',
    BUY_VEHICLE = 'buy_vehicle',
    OTHER_CONSUMPTION = 'other_consumption',
    CONSOLIDATE_LOANS = 'consolidate_loans',
}
